/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useState, useEffect, FC} from "react"
import {Link} from "gatsby"
import {Box, Card, Stack, Typography} from "@mui/material";


const BlogList: FC<{ allPosts: any, category: any }> = (props) => {

  const posts = props.allPosts.filter(post => post.frontmatter.category === props.category);

  return (
    <Stack gap={1}>
      {posts.map((post) => {
        const title = post.frontmatter.title || post.fields.slug
        return (
          <Link to={post.fields.slug} itemProp="url" style={{ textDecoration: 'none' }}>
            <Stack
              p={1}
              borderRadius="1px"
              sx={{
                // backgroundColor: '#1b4275',
                '&:hover': { backgroundColor: '#f0f1f1' }
            }}
            >
              <Typography
                variant="h2"
                sx={{
                  // color: '#FFFFFF',
                  fontSize: '36px'
                }}
              >
                <span itemProp="headline">{title}</span>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  // color: '#FFFFFF'
                }}
              >
                <small>{post.frontmatter.date}</small>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  // color: '#FFFFFF'
                }}
              >
                {post.frontmatter.description}
              </Typography>
            </Stack>
          </Link>
        )
      })}
    </Stack>
  )
}

export default BlogList;

import React, {useState, FC} from "react"
import {graphql} from "gatsby"

import Bio from "../components/Common/bio"
import Layout from "../components/Common/layout"
import SEO from "../components/Common/seo"
import {Box, Tab, Tabs} from '@material-ui/core';
import BlogList from "../components/Common/BlogList";


const BlogIndex: FC<{ data: any, location: any }> = (props) => {
  const siteTitle = props.data.site.siteMetadata?.title || `Title`
  const posts = props.data.allMarkdownRemark.nodes
  const [currentTab, setCurrentTab] = useState('thoughts');

  var pinnedPosts = posts.filter(post => post.frontmatter.isPinned === true)
  const [openedPost, setOpenedPost] = useState(null);


  var style = {
    listItem: {
      "margin-bottom": 60
    },
    article: {
      "border-radius": 25,
      background: "#f8f8fa",
      padding: 20,
      "box-shadow": "2px 2px 18px rgba(0, 0, 0, 0.08)"
    },
    expandButtons: {
      padding: 0,
      border: "none",
      background: "none",
      "margin-top": 15,
      "font-size": 15,
      "margin-left": 10

    },
    expandButtonLink: {
      "text-decoration": "none",
      "font-weight": "bold"
    },
    tab: {
      // color: "#e6e6e6",
      "background": "none",
      // fontColor: "#e6e6e6"
    }
  }

  const tabs = [
    {value: 'thoughts', label: 'Thoughts'},
    {value: 'coding', label: 'Coding'},
    {value: 'about', label: 'About'}
  ];

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };


  if (posts.length === 0) {
    return (
      <Layout title={siteTitle}>
        <SEO title="All posts"/>
        <Bio/>
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout title={siteTitle} maxWidth="md">
      <SEO title="My Blog"/>
      <Box mt={3}>
        <Tabs
          onChange={handleTabsChange}
          scrollButtons="auto"
          value={currentTab}
          variant="scrollable"
          indicatorColor="primary"
          style={style.tab}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
            />
          ))}
        </Tabs>
      </Box>
      <Box mt={3}>
        {currentTab === 'thoughts' && <BlogList allPosts={posts} category={"thoughts"}/>}
        {currentTab === 'coding' && <BlogList allPosts={posts} category={"coding"}/>}
        {currentTab === 'about' && <BlogList allPosts={posts} category={"about"}/>}
      </Box>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            nodes {
                html
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "MMMM DD, YYYY")
                    title
                    description
                    isPinned
                    category
                }
            }
        }
    }
`
